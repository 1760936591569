import React, {useEffect, useState} from 'react';
import HeaderOne from '../common/header/HeaderOne';
import BlogSidebar from '../component/blog/BlogSidebar';
import BlogGridOne from '../component/blog/BlogGridOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import ScrollToTopButton from "../common/ScrollToTopButton";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import FooterTwo from "../common/footer/FooterTwo";
import LoadingSpinner from "../common/LoadingSpinner";
import {fetchAPIData} from "../utils/api/apiController";
import {fetchFirestoreData} from "../utils/firebase/firebaseController";

const headerImageSrc = "/images/banner/nara-blog-thumbnail.png";

const BlogGridView = () => {
    const [blogDataList, setBlogDataList] = useState(null);  // Initialize as null
    const [blogInformation, setBlogInformation] = useState(null);  // Initialize as null
    const [loading, setLoading] = useState(true);  // Loading state
    const [error, setError] = useState(null);      // Error state
    const [socialData, setSocialData] = useState([]);
    const [blogCategoryList, setBlogCategoryList] = useState([]);
    const [blogReadingList, setBlogReadingList] = useState([]);

    function extractUniqueCategories(blogDataList) {
        const allCategories = blogDataList.flatMap(item => item.categories || []); // Extract all categories

        const uniqueCategories = [
            ...new Set(
                allCategories.map(category =>
                    category.charAt(0).toUpperCase() + category.slice(1).toLowerCase() // Capitalize first letter
                )
            ),
        ];

        // Shuffle the array using Fisher-Yates algorithm
        for (let i = uniqueCategories.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1)); // Pick a random index
            [uniqueCategories[i], uniqueCategories[j]] = [uniqueCategories[j], uniqueCategories[i]]; // Swap
        }

        return uniqueCategories;
    }

    // Fetch "blog_social_links" data
    useEffect(() => {
        const unsubscribe = fetchFirestoreData('blog_social_links', setSocialData, 'order', 'published');

        // Clean up the listener when the component unmounts
        return () => {
            unsubscribe(); // Stop listening to Firestore changes
        };
    }, []);

    // Fetch "blog_reading_list" data
    useEffect(() => {
        const unsubscribe = fetchFirestoreData('blog_reading_lists', setBlogReadingList, 'order', 'published');

        // Clean up the listener when the component unmounts
        return () => {
            unsubscribe(); // Stop listening to Firestore changes
        };
    }, []);

    // Fetch "blog_information" data
    useEffect(() => {
        const unsubscribe = fetchFirestoreData('blog_information', setBlogInformation);

        // Clean up the listener when the component unmounts
        return () => {
            unsubscribe(); // Stop listening to Firestore changes
        };
    }, []);

    // Fetch data from API after receiving feed_url from Firestore
    useEffect(() => {
        const getData = async () => {
            if (!blogInformation || !blogInformation[0]?.feed_url) {
                // If blogInformation is null or feed_url is missing, return early
                return;
            }

            try {
                const feedUrl = blogInformation[0]?.feed_url;  // Get the feed_url from Firestore
                const apiResult = await fetchAPIData(feedUrl); // Call the API using the feed_url
                if (Array.isArray(apiResult.items)) {
                    setBlogDataList(apiResult.items);
                    setBlogCategoryList(extractUniqueCategories(apiResult.items));
                } else {
                    throw new Error("Data from API is not an array");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error.message);
            } finally {
                setLoading(false);  // Set loading to false after fetch attempt
            }
        };

        getData();
    }, [blogInformation]);  // Trigger effect when blogInformation changes

    if (loading) {
        return <LoadingSpinner/>;  // Show loading message
    }

    if (error) {
        return <p>Error loading projects: {error}</p>;  // Show error message if fetch fails
    }

    return (
        <>
            <SEO
                title={`Blog - ${process.env.REACT_APP_OWNER}`}
                name={process.env.REACT_APP_OWNER}
                description={process.env.REACT_APP_SEO_DESCRIPTION}
                keywords={process.env.REACT_APP_SEO_KEYWORDS}
                author={`${process.env.REACT_APP_OWNER} | ${process.env.SEO_TAG}`}
                image={process.env.REACT_APP_SEO_IMAGE}
            />
            <ColorSwitcher/>
            <ScrollToTopButton/>
            <main className="main-wrapper">
                <HeaderOne/>
                <div>
                    <BcrumbBannerOne
                        title="Nara's Journal"
                        paragraph="by Kavindu Narathota"
                        styleClass=""
                        mainThumb={headerImageSrc || "/images/banner/banner-thumb-1.png"}/>
                </div>
                <div className="section-padding-equal">
                    <div className="container">
                        {/* Render the content or loading spinner as appropriate */}
                        {loading ? (
                            <LoadingSpinner/>
                        ) : error ? (
                            <p>Error loading blogs: {error}</p>
                        ) : (
                            <div className="row row-40">
                                <div className="col-lg-8">
                                    <BlogGridOne mediumBlogPostList={blogDataList}/>
                                </div>
                                <div className="col-lg-4">
                                    <BlogSidebar BlogSocials={socialData} BlogCategoriesList={blogCategoryList}
                                                 BlogReadingList={blogReadingList}/>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
                <FooterTwo/>
            </main>
        </>
    )
}

export default BlogGridView;
import axios from "axios";

/**
 * Fetches data from a third-party API using Axios.
 * @param {string} url - The API endpoint URL.
 * @returns {Promise<Object>} - The API response data.
 */
export const fetchAPIData = async (url) => {
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error("🌍 API Fetch Error:", error);
        throw error;
    }
};

/**
 * Sends a POST request to a given API.
 * @param {string} url - The API endpoint URL.
 * @param {Object} payload - The data to be sent.
 * @param {Object} headers - Optional headers.
 * @returns {Promise<Object>} - The API response data.
 */
export const postAPIData = async (url, payload, headers = {}) => {
    try {
        const response = await axios.post(url, payload, { headers });
        return response.data;
    } catch (error) {
        console.error("🔥 API Post Error:", error);
        throw error;
    }
};

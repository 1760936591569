import React, {useEffect, useState} from 'react';
import SectionTitle from "../../elements/section-title/SectionTitle";
import ServicePropOne from "./ServicePropOne";
import "../../assets/custom-nara.css"
import LoadingSpinner from "../../common/LoadingSpinner";
import {fetchFirestoreData} from "../../utils/firebase/firebaseController";

const ServiceNara = () => {
    const [serviceData, setServiceData] = useState([]);

    // Fetch "services" data
    useEffect(() => {
        const unsubscribe = fetchFirestoreData('services', setServiceData, 'order', 'published');

        // Clean up the listener when the component unmounts
        return () => {
            unsubscribe(); // Stop listening to Firestore changes
        };
    }, []);
    return (
        <>
            <div className="main-wrapper">
                <div className="section custom-section section-padding bg-color-light">
                    <div className="container">
                        <SectionTitle
                            title="What I Offer"
                            subtitle="Tailored Solutions to Elevate Your Products and Teams"
                            description={`If you need any of these services, feel free to reach out at <a href="mailto:services@narathota.com">services@narathota.com</a>.`}
                            textAlignment="heading-left"
                            textColor=""
                        />
                        <div className="row">
                            {serviceData.length > 0 ? (
                                <ServicePropOne colSize="col-lg-6 col-md-6" serviceStyle="service-style-2" itemShow="4"
                                                marginTop="no" servicesList={serviceData}/>
                            ) : (
                                <LoadingSpinner/> // Show loading text if no data is fetched
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServiceNara;
import React, {useState} from "react";
import Papa from "papaparse";
import {uploadCSVDataToFirestore} from "../utils/firebase/firebaseController";

const CSVUploader = () => {
    const [csvFile, setCsvFile] = useState(null);
    const [collectionName, setCollectionName] = useState("");

    // Handle file selection
    const handleFileChange = (event) => {
        setCsvFile(event.target.files[0]);
    };

    // Handle form submission
    const handleUpload = (event) => {
        event.preventDefault();

        if (!csvFile || !collectionName) {
            alert("Please select a CSV file and enter a collection name.");
            return;
        }

        // Parse the CSV file
        Papa.parse(csvFile, {
            header: true,
            skipEmptyLines: true,
            complete: async (result) => {
                try {
                    // Upload parsed data to Firestore
                    await uploadCSVDataToFirestore(collectionName, result.data);
                    alert("CSV data successfully uploaded to Firestore!");
                } catch (error) {
                    alert("Error uploading CSV data to Firestore.");
                }
            },
            error: (error) => {
                console.error("Error parsing CSV:", error);
                alert("Error parsing CSV file.");
            },
        });
    };

    return (
        <div>
            <h2>Upload CSV to Firestore</h2>
            <form onSubmit={handleUpload}>
                <input
                    type="text"
                    placeholder="Enter Firestore Collection Name"
                    value={collectionName}
                    onChange={(e) => setCollectionName(e.target.value)}
                    required
                />
                <input type="file" accept=".csv" onChange={handleFileChange} required/>
                <button type="submit">Upload CSV</button>
            </form>
        </div>
    );
};

export default CSVUploader;

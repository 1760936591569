import React, {useEffect, useState} from 'react';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import ScrollToTopButton from "../common/ScrollToTopButton";
import FooterTwo from "../common/footer/FooterTwo";
import LinkTreeComponent from "../component/linktree/LinkTreeComponent";
import "../assets/custom-nara.css"
import LoadingSpinner from "../common/LoadingSpinner";
import {fetchFirestoreData} from "../utils/firebase/firebaseController";

const LinkTree = () => {
    const [socialData, setSocialData] = useState([]);

    // Fetch "social_links" data
    useEffect(() => {
        const unsubscribe = fetchFirestoreData('social_links', setSocialData, 'order', 'linktree_published');

        // Clean up the listener when the component unmounts
        return () => {
            unsubscribe(); // Stop listening to Firestore changes
        };
    }, []);

    return (
        <>
            <SEO
                title={`Socials - ${process.env.REACT_APP_OWNER}`}
                name={process.env.REACT_APP_OWNER}
                description={process.env.REACT_APP_SEO_DESCRIPTION}
                keywords={process.env.REACT_APP_SEO_KEYWORDS}
                author={`${process.env.REACT_APP_OWNER} | ${process.env.SEO_TAG}`}
                image={process.env.REACT_APP_SEO_IMAGE}
            />
            <ColorSwitcher/>
            <ScrollToTopButton/>
            <main className="main-wrapper">
                <HeaderOne/>
                <div className="resume-spacer-div"/>
                <div className="custom-wrapper-social">
                    {socialData.length > 0 ? (
                        <LinkTreeComponent SocialData={socialData}/>
                    ) : (
                        <LoadingSpinner/> // Show loading text if no data is fetched
                    )}
                </div>
                <FooterTwo/>
            </main>
        </>
    );
}

export default LinkTree;


import { db, auth } from "./firebaseConfig";
import { addDoc, collection, onSnapshot, orderBy, query } from "firebase/firestore";

/**
 * Fetches real-time data from a Firestore collection.
 */
export const fetchFirestoreData = (collectionName, setDataCallback, orderByField = null, filterByField = null) => {
    let dataQuery = collection(db, collectionName);
    if (orderByField) {
        dataQuery = query(dataQuery, orderBy(orderByField));
    }

    return onSnapshot(dataQuery, (snapshot) => {
        const data = snapshot.docs
            .map(doc => ({ id: doc.id, ...doc.data() }))
            .filter(item => (filterByField ? item[filterByField] === true : true));

        setDataCallback(data);
    }, (error) => {
        console.error(`Firestore Error [${collectionName}]:`, error);
    });
};

/**
 * Uploads an array of data to a Firestore collection.
 */
export const uploadCSVDataToFirestore = async (collectionName, data) => {
    try {
        const collectionRef = collection(db, collectionName);
        await Promise.all(data.map(item => addDoc(collectionRef, item)));
        console.log(`✅ Data successfully uploaded to Firestore (${collectionName}).`);
    } catch (error) {
        console.error("🔥 Firestore Upload Error:", error);
        throw error;
    }
};

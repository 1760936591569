import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaLinkedin } from "react-icons/fa";
import { FaMedium } from "react-icons/fa6";
import {fetchFirestoreData} from "../../utils/firebase/firebaseController";

const iconMapping = {
    linkedin: <FaLinkedin />,
    medium: <FaMedium />
};

const Nav = () => {
    const [navbarVisibilityList, setNavbarVisibilityList] = useState([]);

    useEffect(() => {
        const unsubscribe = fetchFirestoreData(
            'navbar_visibility',
            setNavbarVisibilityList,
            "order",
            "published"
        );

        return () => {
            unsubscribe(); // Clean up listener
        };
    }, []);

    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                {navbarVisibilityList.map((item) => (
                    <li key={item.id}>
                        {item.icon && iconMapping[item.icon] ? (
                            // Only show the icon for LinkedIn and Medium
                            <a href={item.path} target="_blank" rel="noopener noreferrer">
                                {iconMapping[item.icon]}
                            </a>
                        ) : item.type === "link" ? (
                            <Link to={process.env.PUBLIC_URL + item.path}>{item.title}</Link>
                        ) : item.type === "external" ? (
                            <a href={item.path} target="_blank" rel="noopener noreferrer">{item.title}</a>
                        ) : null}
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Nav;

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCYRO7XfvpRfWszHcOqw0DWjFMGH4IdsJo",
    authDomain: "narathota-web-2025.firebaseapp.com",
    projectId: "narathota-web-2025",
    storageBucket: "narathota-web-2025.firebasestorage.app",
    messagingSenderId: "286058947380",
    appId: "1:286058947380:web:a87fd8f57413671338fb61",
    measurementId: "G-ZQWBKSCNYN"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const db = getFirestore(app);
const auth = getAuth(app);

export { app, db, auth };

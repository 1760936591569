import React, {useEffect, useState} from 'react';
import {AnimationOnScroll} from 'react-animation-on-scroll';
import Tilty from 'react-tilty';
import LoadingSpinner from "../../common/LoadingSpinner"; // Import fetchFirestoreData from DataFetcher
import "../../assets/custom-nara.css"
import {fetchFirestoreData} from "../../utils/firebase/firebaseController";

const BannerThree = () => {
    const [aboutData, setAboutData] = useState([]);

    // Call the fetch function and set up real-time listener
    useEffect(() => {
        const unsubscribe = fetchFirestoreData('about_me', setAboutData);

        // Clean up the listener when the component unmounts
        return () => {
            unsubscribe(); // Stop listening to Firestore changes
        };
    }, []); // Only run on mount and unmount

    return (
        <div className="banner banner-style-3 banner-custom">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="banner-content">
                            <AnimationOnScroll animateIn="slideInUp" duration={1} animateOnce={true} delay={100}>
                                <span className="subtitle">{process.env.REACT_APP_OWNER}</span>
                            </AnimationOnScroll>
                            <AnimationOnScroll animateIn="slideInUp" duration={1} animateOnce={true} delay={200}>
                                {/* Render the headlines from the aboutData array */}
                                {aboutData.length > 0 ? (
                                    aboutData.map((item, index) => (
                                        <div key={index}>
                                            {item.headline.length > 0 ? (
                                                item.headline.map((item2, index2) => (
                                                    <h2 className="title" key={index2}>{item2}</h2> // Display each headline in a new line
                                                ))
                                            ) : (
                                                <LoadingSpinner/> // Show loading text if no data is fetched
                                            )}
                                        </div>
                                    ))
                                ) : (
                                    <LoadingSpinner/> // Show loading text if no data is fetched
                                )}
                            </AnimationOnScroll>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="banner-thumbnail">
                            <div className="large-thumb">
                                <AnimationOnScroll animateIn="slideInUp" duration={1} animateOnce={true} delay={200}>
                                    <Tilty perspective={2000} reset={false}>
                                        <img src={process.env.PUBLIC_URL + "/images/nara/headshot1.png"} alt="Shape"/>
                                    </Tilty>
                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="list-unstyled shape-group-20">
                <li className="shape shape-1">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-32.png"} alt="Bubble"/>
                </li>
                <li className="shape shape-2">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-33.png"} alt="Bubble"/>
                </li>
                <li className="shape shape-4">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble"/>
                </li>
                <li className="shape shape-5">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-4.png"} alt="Bubble"/>
                </li>
                <li className="shape shape-6">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-30.png"} alt="Bubble"/>
                </li>
                <li className="shape shape-7">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-31.png"} alt="Bubble"/>
                </li>
            </ul>
        </div>
    );
};

export default BannerThree;

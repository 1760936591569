import React from 'react';
import '../assets/custom-nara.css';

const LoadingSpinner = () => {
    return (
        <div className="loading-bar-container">
            <div className="loading-bar" />
            <p className="loading-text">Content Loading...</p>
        </div>
    );
};

export default LoadingSpinner;
